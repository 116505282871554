<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition" 
   persistent no-click-animation>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Hello. Please, choose your division:</v-toolbar-title>

        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="cyan lighten-3"
          ></v-progress-linear>

        <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="cancel()">Cancel & Sign Out</v-btn>           
          </v-toolbar-items>
      </v-toolbar>
      <v-subheader>We noticed that you have more than one role associated with your profile. Please choose one of the options below.</v-subheader>

        <v-row class="ma-0">

          <v-col>
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">Access Company Dashboard</v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar tile size="100">
                  <v-img src="https://via.placeholder.com/150?q=Company"></v-img>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn color="primary" @click="selectDivision(division)">Go To Company</v-btn>                
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      
    </v-card>
  </v-dialog>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {  
  data: function(){
    return{
      loading: true,
      dialog: true,
      division: 'default'
    }
  },
  methods: {
    cancel() {
      Auth.signOut()
    },
    selectDivision(){
      this.$router.push({name: 'groups.list'})
    }
  }
}
</script>